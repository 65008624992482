import React, { FC, useState, ChangeEvent } from 'react';

import { post, upload } from 'js/api-helper';

import Heading from '../../../components/Heading/Heading';
import Accordion from '../../../components/Accordion/Accordion';

import PageLinkMenu from 'components/PageLinkMenu/PageLinkMenu';
import { LinkItem } from 'types/LinkItem';

import FormSubmitBox from '../../../components/FormSubmitBox/FormSubmitBox';
import Modal from '../../../components/Modal/Modal';
import ReccomendationLabel, { SeriositetsLabels } from '../../../components/ReccomendationLabel/ReccomendationLabel';
import Text from 'components/Text/Text';
import Button from 'components/Button/Button';
import Field from 'components/Field/Field';
import Checkbox from 'components/Checkbox/Checkbox';
import Alert from 'components/Alert/Alert';
import { ObjektVurdering } from 'types/ObjektVurdering';
import RapportVisning from 'types/no-gen/RapportVisning';

export interface SeriositetsRapportSide {
  title: string;
  summary: { title: string; text: string; anbefaling: ObjektVurdering }[];
  seriositetsSjekkFullfort: boolean;

  visning: number;
  pageLinks?: LinkItem[];
  sendAnbefalingerUrl?: string; // url hvor PL kan sende anbefaling
  angreAnbefalingerUrl?: string; // url hvor PL kan angre anbefalinger
  anbefalingerGodkjentKommentar?: string; //evt. kommentarer OG har gitt
  anbefalingerGodkjennUrl?: string;
  anbefalingerGodkjentDato?: string;

  readOnly: boolean;
}

interface ModalData {
  open: boolean;
  title: string;
  body: string;
}

const SeriositetsRapportSide: FC<SeriositetsRapportSide> = ({
  title,
  pageLinks,
  summary,
  seriositetsSjekkFullfort,
  anbefalingerGodkjennUrl,
  visning,
  readOnly
}) => {
  const [formdata, setFormdata] = useState({
    betingelserGodtatt: visning == RapportVisning.godkjentRapportView ?? false
  });

  const [view, setView] = useState<RapportVisning>(visning);

  const [modal, setModal] = useState<ModalData>({ open: false, title: '', body: '' });

  const [error, setError] = useState<string>();

  // const submitReccomendations = async (url) => {
  //   try {
  //     const res = await post(url, { anbefalingerSendt: true });
  //     if (res) {
  //       setModal({
  //         open: true,
  //         title: 'Evalueringer og anbefalinger sendt til oppdragsgiver for godkjennelse',
  //         body: 'Oppdragsgiver vil nå varsles om at seriøsitetsjekken er evaluert med anbefalninger. Du vil få en epost når Oppdragsgiver har gitt sin godkjennelse.'
  //       });
  //       setView(RapportVisning.levertRapportView);
  //     }
  //   } catch (error) {
  //     setError('oops! noe gikk galt!');
  //   }
  // };

  const acceptReccomendations = async (url) => {
    //post submit, submit empty comment; it's illeagal to submit en empty request body
    try {
      const res = await post(url, { kommentar: '' });
      if (res) {
        setModal({
          open: true,
          title: 'Godkjennelsen er mottatt',
          body: 'Seriøsitetssjekken er nå fullført og prosessen vil gå videre til konkurranse.'
        });
        setView(RapportVisning.godkjentRapportView);
      }
    } catch (error) {
      setError('oops! noe gikk galt!');
    }
  };

  // const regretReccomendations = async (undoUrl) => {
  //   try {
  //     const res = await post(undoUrl);
  //     if (res) {
  //       setView(RapportVisning.redigerRapportView);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setError('oops! noe gikk galt!');
  //   }
  // };

  //TODO: endre tekst på alt

  return (
    <div className="rapport">
      <Heading>{title}</Heading>
      {pageLinks && pageLinks?.length > 0 && <PageLinkMenu pageLinks={pageLinks} />}
      <Accordion title="Seriøsitetsvurdering og kommentarer" defaultOpen={summary.length > 0}>
        <div className="summary">
          {summary &&
            seriositetsSjekkFullfort &&
            summary.map((item) => {
              return (
                <div className="summary__item" key={item.title}>
                  <div>
                    <h3>{item.title}</h3>
                    <ReccomendationLabel anbefalingsEnum={SeriositetsLabels} vurdering={item.anbefaling} />
                  </div>
                  <p>{item.text}</p>
                </div>
              );
            })}
        </div>
      </Accordion>
      <Modal
        title={modal.title}
        open={modal.open}
        onClose={() =>
          setModal({
            ...modal,
            open: false
          })
        }
      >
        <p>{modal.body}</p>
      </Modal>
      {view === RapportVisning.redigerRapportView && !readOnly && (
        <>
          <FormSubmitBox
            heading={'Godkjenn evaluering'}
            bodyText={'Først når alle objekter er evaluert kan prosjektleder godkjenne'}
            disabled={true}
            buttonText={'Godkjenn'}
            submitFunc={(url) => {
              void 0;
            }}
          />
        </>
      )}
      {view === RapportVisning.lesRapportView && !readOnly && (
        <form className="SeriositetEvaluering">
          <h2>Godkjenn</h2>

          <Text variant="mono-16">Prosjektleders godkjenning</Text>
          <div className="font-paragraph">
            <Checkbox
              name="godkjent"
              label="Oppsummeringer og anbefalinger er lest og godkjent"
              checked={formdata.betingelserGodtatt}
              onChange={(e) => {
                setFormdata({
                  ...formdata,
                  betingelserGodtatt: e.currentTarget.checked
                });
              }}
            ></Checkbox>
          </div>
          <div className="SeriositetEvaluering--bottom">
            <p>For å gå videre til konkurransen må du som prosjektleder bekrefte at du har lest og godkjent vurderingene</p>

            <Button
              disabled={!formdata.betingelserGodtatt}
              onClick={() => {
                acceptReccomendations(anbefalingerGodkjennUrl);
              }}
            >
              Godkjenn
            </Button>
          </div>
        </form>
      )}
      {view === RapportVisning.godkjentRapportView && !readOnly && (
        <div className="SeriositetEvaluering">
          <div className="font-paragraph-header">Rapporten er innsendt.</div>
          <div className="font-paragraph">
            <Text variant="mono-16">Prosjektleders godkjenning</Text>
          </div>
          <div className="font-paragraph">
            <Checkbox name="godkjent" label="Oppsummeringer er lest og godkjent" checked={true}></Checkbox>
          </div>
        </div>
      )}
      {error && <Alert kind="danger">{error}</Alert>}
    </div>
  );
};

export default SeriositetsRapportSide;
