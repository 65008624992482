import SingleDatePicker from 'components/DateRangePicker/SingleDatePicker';
import FormSubmitBox from 'components/FormSubmitBox/FormSubmitBox';
import Modal from 'components/Modal/Modal';
import { post } from 'js/api-helper';
import React, { FC } from 'react';
import { useState } from 'react';
import { KonkurransegrunnlagSide } from '../KonkurransegrunnlagSide';
import { shortDateString } from 'js/date-helper';

interface ProjectManagerArea extends KonkurransegrunnlagSide {
  bodyTextOverride?: string;
}

const ProjectManagerArea: FC<ProjectManagerArea> = ({
  sendTilGodkjenningUrl,
  publiserGrunnlagUrl,
  anbefalingerSendt,
  anbefalingerGodkjent,
  grunnlagPublisert,
  avsluttKonkurranseUrl,
  leveranseFristUtc,
  bodyTextOverride
}) => {
  const [approved, setApproved] = useState(anbefalingerSendt);
  const [published, setPublished] = useState(grunnlagPublisert);
  const [modal, setModal] = useState(false);
  const [regretModal, setRegretModal] = useState(false);
  const [date, setDate] = useState<Date | undefined>(leveranseFristUtc ? new Date(leveranseFristUtc) : undefined);

  const reloadPage = () => {
    window.location.reload();
  };

  const submitDocs = async (url: string) => {
    try {
      const res = await post(url, { grunnlagPublisert: true });
      if (res) {
        setApproved(true);
      }
    } catch (error) {
      console.log('error!');
    }
  };

  const publishDocs = async (url: string) => {
    try {
      const res = await post(url, { grunnlagPublisert: true, leveranseFristUtc: date });
      if (res) {
        setPublished(true);
        setModal(true);
      }
    } catch (error) {
      console.log('error!');
    }
  };

  const regretPublish = async () => {
    try {
      const res = await post(avsluttKonkurranseUrl, { grunnlagPublisert: false });
      if (res) {
        setPublished(false);
        setRegretModal(false);
      }
    } catch (error) {
      console.log('error!');
    }
  };

  if (anbefalingerGodkjent) {
    return (
      <FormSubmitBox
        heading="Publiser konkurransegrunnlaget"
        bodyText={
          bodyTextOverride ??
          'Oppdragsgiver har gitt sin godkjennelse, du kan nå publisere dokumentene som da blir distribuert til markedsaktørene som er invitert til konkurransen.'
        }
        buttonText={'Publiser dokumenter'}
        regretButtonText="Avbryt konkurranse"
        dokumenterPublisert={published}
        disabled={!date}
        submitUrl={published ? avsluttKonkurranseUrl : publiserGrunnlagUrl}
        submitFunc={(url) => {
          publishDocs(url);
        }}
        regretFunc={(url) => {
          setRegretModal(true);
        }}
      >
        <SingleDatePicker
          initialDate={date}
          disabled={published}
          label={'Leveransefrist'}
          placeholder={'dd.mm.åååå'}
          onDateChange={(e) => setDate(e)}
        />
        <Modal title={'Konkurransegrunnlaget er publisert'} clickOutsideToClose={true} open={modal} onClose={() => reloadPage()}>
          <p>
            Konkurransegrunnlaget distribueres nå til markedsaktører.<br></br>
            Du vil få en epost når markedsaktører har sendt inn sine svar.
          </p>
        </Modal>

        <Modal title={'Er du sikker?'} clickOutsideToClose={true} open={regretModal} onClose={() => setRegretModal(false)}>
          <p>Er du sikker på at du vil avbryte konkurransen?</p>
          <button className="cancel-comp-button" onClick={() => regretPublish()}>
            Avbryt konkurranse
          </button>
        </Modal>
      </FormSubmitBox>
    );
  } else {
    enum HeaderVariations {
      'Send til oppdragsgiver for godkjennelse',
      'Sendt til oppdragsgiver for godkjennelse'
    }

    enum BodyVariations {
      'Konkurransegrunnlaget er ikke publisert, du må be om oppdragsgivers godkjennelse før du kan publisere.',
      'Konkurransegrunnlaget er sendt til oppdragsgiver.'
    }

    return (
      <FormSubmitBox
        heading={HeaderVariations[approved ? 1 : 0]}
        bodyText={BodyVariations[approved ? 1 : 0]}
        buttonText={'Send til oppdragsgiver'}
        dokumenterPublisert={false}
        submitUrl={sendTilGodkjenningUrl}
        submitFunc={(url) => {
          submitDocs(url);
          setModal(true);
        }}
        regretFunc={() => {}}
        disabled={approved}
      >
        <Modal
          title={'Prosjektleders anbefaling er mottatt'}
          clickOutsideToClose={true}
          open={modal}
          onClose={() => setModal(false)}
        >
          <p>Prosjektleder vil nå ta stilling til grunnlaget</p>
        </Modal>
      </FormSubmitBox>
    );
  }
};

export default ProjectManagerArea;
