import React, { FC } from 'react';
import type { LinkItem } from 'types/LinkItem';
import PageLinkMenu from 'components/PageLinkMenu/PageLinkMenu';
import type { DokumentSkjemadel } from 'types/DokumentSkjemadel';
import Text from 'components/Text/Text';
import useArrayState from '@clave/use-array-state';
import DokumentSkjema from 'components/DokumentSkjema/DokumentSkjema';
import ProjectManagerArea from './ProjectManagerArea/ProjectManagerArea';

export interface KonkurransegrunnlagSide {
  readonly tittel: string;
  readonly sideLenker?: LinkItem[];

  readonly sendTilGodkjenningUrl?: string;
  readonly godkjennUrl?: string;
  readonly publiserGrunnlagUrl: string;
  readonly avsluttKonkurranseUrl: string;
  readonly editVedleggUrl: string;
  readonly slettVedleggUrl: string;
  readonly oppdaterFilStatusUrl: string;

  readonly anbefalingerSendt: boolean;
  readonly anbefalingerGodkjent: boolean;
  readonly grunnlagPublisert: boolean;

  readonly leveranseFristUtc?: string;
  readonly oppdragsGiverKommentar: string;
  readonly rolle: string;
  readonly skjemaDeler: DokumentSkjemadel[];
}

const KonkurransegrunnlagSide: FC<KonkurransegrunnlagSide> = (props: KonkurransegrunnlagSide) => {
  const [dynamicDocs, setDynamicDocs] = useArrayState<DokumentSkjemadel>(props.skjemaDeler);

  const allowEditRoles = ['PL', 'FR'];

  return (
    <div className={'KonkurransegrunnlagSide'}>
      <Text component="h1" variant="list-title">
        {props.tittel}
      </Text>
      {props.sideLenker && <PageLinkMenu pageLinks={props.sideLenker} />}
      <DokumentSkjema
        oppdaterFilStatusUrl={props.oppdaterFilStatusUrl}
        skjemaDeler={dynamicDocs}
        slettVedleggUrl={props.slettVedleggUrl}
        editVedleggUrl={props.editVedleggUrl}
        readonly={false}
      />
      {allowEditRoles.includes(props.rolle) && (
        <ProjectManagerArea
          {...props}
          anbefalingerSendt={true}
          anbefalingerGodkjent={true}
          oppdragsGiverKommentar={''}
          bodyTextOverride={
            'Publiser konkurransegrunnlaget ovenfor markedsaktører og sett en svarfrist. Markedsaktører bes svare på dokumentene du legger til her.'
          }
        /> //hack in the interest of SI-690
      )}
    </div>
  );
};

export default KonkurransegrunnlagSide;
